<template>
  <div class="container">
    <div class="pa-20">
      <img class="logo" src="~@/assets/img/logo.png" />
      <div class="content flex f-center flex-direction f-jcenter">
        <img class="head" src="~@/assets/img/success.png"/>
        <div class="font-bold t-center fon-18 ma-tb-30">
          确认成功
        </div>
        <div class="width60 ma-b-20" @click="$router.replace('/leave/multiple')">
          <van-button type="primary"  class="width100" color="#4E9900">继续放行</van-button>
        </div>
        <div class="width60" @click="toPage">
          <van-button type="primary" class="width100" color="#003AA9">回到首页</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {
    };
  },
  created() {
    _this = this;
  },
  methods: {
    toPage() {
      _this.$router.replace('/')
    }
  },
};
</script>

<style lang="less" scoped>
.content{
  width: 476px;
  height: 355px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  .head{
    width: 120px;
  }
}
</style>